
import { Options, Vue } from "vue-class-component";

import Page from "@views/page/components/page.vue";
import LogsView from "@views/page/logs-view.vue";

import ViewFilter from "@views/page/view-filter.vue";
//import LogsViewFilter from "@views/page/logs-view-filter.vue";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";
import PageRequest from "@/dataSource/api/pagination-helper/lib/PageRequest";

@Options<ActiveMethodsLogsViewPage>({
  components: {
    Page,
    //LogsViewFilter,
    ViewFilter,
    LogsView,
  },
  props: { slotProps: {} },

  watch: {
    //параметры заходящие через слот
    //пока только идентификатор источника логов(sourceLogId)
    slotProps: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value?.sourceLogId) {
          this.filterModel.source = value.sourceLogId;
        }
      },
    },
    filterModel: {
      deep: true,
      handler(value) {
        // this.saveInLocalStor();
      },
    },
  },
})
export default class ActiveMethodsLogsViewPage extends Vue {
  readonly slotProps = {};
  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();
  filterModelView: FilterModel = new FilterModel();
  getUtc = getUtc;
  ver = "1";
  beforeMount() {
    this.getInLocalStor();

    // подписываемся на событие отфильтровать в компоненте Page
    document.addEventListener(
      "filterChange",
      this.onclickFilterChange.bind(this)
    );
    //чтобы скопировать значение, а ссылку  и исключить реактивность. Обновление только по кнопке отфильтровать.
    // this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
  }
  destroy() {
    document.removeEventListener(
      "filterChange",
      this.onclickFilterChange.bind(this)
    );
  }
  filterData() {
    this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
    this.onclickFilterChange()
  }
  onclickFilterChange() {


    // ловим событие нажатия кнопки отфильтровать
    this.filterModel.DateTo = this.filterModel.DateTo;
    this.filterModel.DateFrom = this.filterModel.DateFrom;

    // this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
    this.filterModelView.OnlyActiveRequests = true;
    // this.filterModelView.LogLevel = undefined;
    //сохраняем в локал сторедж состояние фильтра для текущей страницы
    this.saveInLocalStor();
    (this.$refs.logsView as any).onFilterChange();
  }

  //сохранить модель фильтра для страницы
  saveInLocalStor() {
    localStorage.activeMethodLogsViewPage = JSON.stringify(
      this.filterModelView
    );
  }
  //получить данные фильтра для страницы если они есть
  getInLocalStor() {
    if (localStorage.activeMethodLogsViewPage) {
      this.filterModel = JSON.parse(localStorage.activeMethodLogsViewPage);
      const t = 0// new Date().getTimezoneOffset();
      this.filterModel.DateFrom = this.addMinutes(this.filterModel.DateFrom, t);
      this.filterModel.DateTo = this.addMinutes(this.filterModel.DateTo, t);
      // this.filterModel.DateTo = new Date(this.filterModel.DateTo);
    }
  }

  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
  //var date = convertUTCDateToLocalDate(new Date(date_string_you_received));
}
